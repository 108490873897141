<script setup>
import { ref } from "vue";
import AppMenuItem from "./AppMenuItem.vue";

const navbar = ref([
  {
    label: "Localização",
    icon: "fa-solid fa-location-dot",
    items: [
      {
        label: "Monitoramento",
        icon: "fa-solid fa-location-dot",
        to: "/monitoramento/open-maps",
      },
    ]
  },
  { separator: true },
  {
    label: "Segurança",
    icon: "pi pi-shield",
    items: [
      {
        label: "Tipo Delitos",
        icon: "fa-solid fa-people-line",
        to: "/prevencao/tipo",
      },
      {
        label: "Natureza Prevenção",
        icon: "fa-solid fa-box-archive",
        to: "/prevencao/natureza",
      },
      {
        label: "Prevenção Qualificada",
        icon: "pi pi-shield",
        to: "/prevencao",
      },
    ],
  },
  { separator: true },
  {
    label: "Organização",
    icon: "fa-solid fa-globe",
    items: [
      {
        label: "Clientes",
        icon: "fa-solid fa-building",
        to: "/cliente",
      },
      {
        label: "Subclientes",
        icon: "fa-solid fa-archway",
        to: "/subcliente",
      },
      {
        label: "Veiculos",
        icon: "fa-solid fa-truck-plane",
        to: "/veiculo",
      },
      {
        label: "Rastreadores",
        icon: "fa-solid fa-satellite-dish",
        to: "/rastreador",
      },
    ],
  },
  { separator: true },
  {
    label: "Administração",
    icon: "fa-solid fa-toolbox",
    items: [
      {
        label: "Usuarios",
        icon: "fa-solid fa-user",
        to: "/usuario",
      },
      {
        label: "Perfis",
        icon: "fa-solid fa-people-group",
        to: "/perfil",
      },
      {
        label: "Permissões",
        icon: "fa-solid fa-people-group",
        to: "/permissao",
      }
    ],
  },
  { separator: true },
  {
    label: "Cadastros",
    icon: "fa-solid fa-clipboard-list",
    items: [
      {
        label: "Cidades",
        icon: "fa-solid fa-city",
        to: "/cidade",
      },
      {
        label: "Estados",
        icon: "fa-solid fa-flag",
        to: "/estado",
      },
      {
        label: "Países",
        icon: "fa-solid fa-flag",
        to: "/pais",
      },
      {
        label: "Combustíveis",
        icon: "fa-solid fa-gas-pump",
        to: "/combustivel",
      },
      {
        label: "Habilitação",
        icon: "fa-solid fa-industry",
        to: "/habilitacao",
      },
      {
        label: "Generos",
        icon: "fa-solid fa-industry",
        to: "/genero",
      },
      {
        label: "Operadoras",
        icon: "fa-solid fa-industry",
        to: "/operadora",
      },
      {
        label: "Status",
        icon: "fa-solid fa-industry",
        to: "/status",
      },
      {
        label: "Time Zones",
        icon: "fa-solid fa-industry",
        to: "/time-zone",
      },
      {
        label: "Tipo Transmissores",
        icon: "fa-solid fa-industry",
        to: "/tipo-transmissor",
      },
      {
        label: "Fabricantes de Rastreadores",
        icon: "fa-solid fa-industry",
        to: "/fabricante-rastreador",
      },
      {
        label: "Modelos de Rastreadores",
        icon: "fa-solid fa-industry",
        to: "/modelo-rastreador",
      },
      {
        label: "Tipo de Rastreadores",
        icon: "fa-solid fa-industry",
        to: "/tipo-rastreador",
      },
      {
        label: "Fabricantes de Veiculos",
        icon: "fa-solid fa-car",
        to: "/fabricante-veiculo",
      },
      {
        label: "Modelos de Veiculos",
        icon: "fa-solid fa-car-side",
        to: "/modelo-veiculo",
      },
    ],
  },
]);
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in navbar" :key="item">
      <AppMenuItem v-if="!item.separator" :item="item" root :index="i" />
      <li v-else class="menu-separator"></li>
    </template>
  </ul>
</template>
