import { api } from "./api";

export class PoliceReportNatureService {
  async post(value: string) {
    try {
      const response = await api.post(`/police-report-nature`, value);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async get() {
    try {
      const response = await api.get(`/police-report-nature`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }

  async getById(id: string) {
    try {
      const response = await api.get(`/police-report-nature/${id}`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }

  async patch(value: string, id: string) {
    try {
      const response = await api.patch(`/police-report-nature/${id}`, value);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async delete(id: string) {
    try {
      const response = await api.delete(`/police-report-nature/${id}`);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }
}
