import axios, { AxiosInstance } from "axios";
import {getConfig} from '@/config';

const config: any = await getConfig()
const API_URL = config.VUE_APP_API_BASE_URL;

const http: AxiosInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true
});

http.interceptors.response.use(
  (reponse) => {
    return reponse
  },
  async (error) => {
    if(error.response.status === 401){
      const response = await axios.get(`${API_URL}/auth/refresh`, { withCredentials: true })
      .catch((error) => {
        return error.response
      })
      if(response && response.data){
        return axios(error.config)
      }
    }else{
      return error.response
    }
  }
);

export const api = http;
