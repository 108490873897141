<script setup>
import { ref } from 'vue';

const x = ref(0);
const y = ref(0);
const closed = ref(false); // Variável para rastrear se o item está fechado

const startDrag = (event) => {
  // Verifica se o item está fechado e retorna se estiver
  if (closed.value) return;

  const initialX = event.clientX - x.value;
  const initialY = event.clientY - y.value;

  const moveHandler = (event) => {
    x.value = event.clientX - initialX;
    y.value = event.clientY - initialY;
  };

  const stopDrag = () => {
    window.removeEventListener('mousemove', moveHandler);
    window.removeEventListener('mouseup', stopDrag);
  };

  window.addEventListener('mousemove', moveHandler);
  window.addEventListener('mouseup', stopDrag);
};

const closeItem = () => {
  // Define a variável closed para true para indicar que o item está fechado
  closed.value = true;
  // Move o item para fora da tela
  x.value = -1000;
  y.value = -1000;
};

// Função para reabrir o item quando um novo marcador é clicado
const openItem = () => {
  closed.value = false;
  // Você pode definir a posição inicial aqui
  x.value = 0;
  y.value = 0;
};

// Escute por um evento para reabrir o item quando um novo marcador é clicado
window.addEventListener('openItem', openItem);
</script>

<style scoped>
div {
  user-select: none; /* Evita a seleção de texto ao arrastar */
}
</style>

<template>
  <div :style="{ position: 'absolute', left: x + 'px', top: y + 'px', cursor: 'move' }" @mousedown="startDrag">
    <button v-if="!closed" @click="closeItem" style="position: absolute; top: -15px; right: -15px;">X</button>
    <slot></slot>
  </div>
</template>