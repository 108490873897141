export interface Config {
  VUE_APP_API_BASE_URL: string;
  VUE_APP_ANOTHER_VARIABLE: string;
}

let config: Config 

export const getConfig = async () => {
  try {
    const response = await fetch('./config.json');
    config = await response.json();
    return config
  } catch (error) {
    console.error('Failed to load config.json:', error);
  }
};