import { api } from "./api";

export class PoliceReportRecordService {
  async post(value: string) {
    try {
      const response = await api.post(`/police-report-record`, value);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async get() {
    try {
      const response = await api.get(`/police-report-record`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }

  async getById(id: string) {
    try {
      const response = await api.get(`/police-report-record/${id}`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }

  async patch(value: string, id: string) {
    try {
      const response = await api.patch(`/police-report-record/${id}`, value);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async delete(id: string) {
    try {
      const response = await api.delete(`/police-report-record/${id}`);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async getOcorrencias(city: string, type: string, nature: string){
    try{
      let url = city+`/${type}`;
      if(nature !== 'undefined'){
        url = url+`/${nature}`
      }
      const response = await api.get(`/police-report-record/analise-criminal/${url}`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }
}
