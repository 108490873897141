import { LoginData } from "@/types/login";
import { api } from "./api";

export class AuthenticationService {
  async auth(values: LoginData) {
    try {
      const response = await api.post(`/auth/login`, values);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async logout() {
    try {
      const response = await api.post(`/auth/logout`, {});
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async isAuthenticated() {
    try {
      const response = await api.get(`/auth/is_authenticated`);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async refresh() {
    try {
      const response = await api.get(`/auth/refresh`);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }
}
