export class openRouteService {
  async getRoute(coordinates: any) {
    try {
      const response = await fetch(`https://api.openrouteservice.org/v2/directions/driving-car/json`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': '5b3ce3597851110001cf6248323da8b5857143d99437cce285a81027'
        },
        body: JSON.stringify(coordinates)
      })
      return response.json();
    } catch (error: any) {
      return error.response;
    }
  }
}

