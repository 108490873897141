<script setup>
import { ref } from "vue";
import { FilterMatchMode } from "primevue/api";

const tableRef = ref(null);
const filterTable = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const props = defineProps({
  dados: { type: Array, required: true },
  dataTable: { type: Array, required: true },
  new: { type: Function },
  del: { type: Function },
  edit: { type: Function },
  view: { type: Function },
});
</script>

<template>
  <div class="w-full">
    <DataTable
      ref="tableRef"
      :value="props.dados"
      paginator
      :rows="10"
      showCurrentPageReport
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
      :rowsPerPageOptions="[10, 25, 50]"
      :globalFilterFields="['nome', 'email', 'name']"
      v-model:filters="filterTable"
      :pt="{
        root: { class: 'w-full' },
      }"
    >
      <template #header>
        <div
          class="flex flex-wrap gap-2 align-items-center justify-content-between"
        >
          <span
            class="p-input-icon-left w-full sm:w-20rem flex-order-1 sm:flex-order-0"
          >
            <i class="pi pi-search"></i>
            <InputText
              v-model="filterTable.global.value"
              placeholder="Busca"
              class="w-full"
            />
          </span>
          <Button
            type="button"
            icon="pi pi-user-plus"
            label="Novo"
            class="w-full sm:w-auto flex-order-0 sm:flex-order-1"
            outlined
            @click="props.new"
            v-if="props.new"
          />
        </div>
      </template>
      <Column
        :field="data.field"
        :header="String(data.header).toUpperCase()"
        sortable
        headerClass="white-space-nowrap"
        v-for="data in dataTable || {}"
        :key="data.header"
      >
      </Column>
      <Column
        header="AÇÕES"
        headerClass="white-space-nowrap headerAlign"
        :headerStyle="{ width: '10%' }"
        v-if="props.edit || props.view || props.del"
      >
        <template #body="data">
          <span class="p-column-title">AÇÕES</span>
          <div class="flex justify-content-center">
            <Button
              type="button"
              icon="fa-solid fa-eye"
              class=""
              severity="info"
              @Click="props.view(data.data.id)"
              text
              rounded
              outlined
              v-if="props.view"
            />
            <Button
              type="button"
              icon="fa-solid fa-pen-to-square"
              class=""
              severity="warning"
              @Click="props.edit(data.data.id)"
              text
              rounded
              outlined
              v-if="props.edit"
            />
            <Button
              type="button"
              icon="fa-solid fa-trash-can"
              class=""
              severity="danger"
              @Click="props.del(data.data.id)"
              text
              rounded
              outlined
              v-if="props.del"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<style>
.headerAlign .p-column-header-content {
  justify-content: center;
}
</style>
