import { api } from "./api";

export class TransmissionTypeService {
  async post(value: string) {
    try {
      const response = await api.post(`/transmission-type`, value);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async get() {
    try {
      const response = await api.get(`/transmission-type`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }

  async getById(id: string) {
    try {
      const response = await api.get(`/transmission-type/${id}`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }

  async patch(value: string, id: string) {
    try {
      const response = await api.patch(`/transmission-type/${id}`, value);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async delete(id: string) {
    try {
      const response = await api.delete(`/transmission-type/${id}`);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }
}
