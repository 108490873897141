import { api } from "./api";

export class ArquivoService {
  async upload(file : any, dataUpload : any) {
    try {
      const formData = new FormData();

      formData.append("file", file);
      formData.append("table_name", dataUpload.tableName);
      formData.append("file_type_id", dataUpload.fileTypeId)
      formData.append("ref", dataUpload.ref);

      const response = await api.post('/files', formData);

      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async deleteArquivo(id: string) {
    try {
      const response = await api.delete(`/files/${id}`);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async findAll(fileTypeId : string, ref : string, ) {
    try {
      const response = await api.get(`/files/multiple/${fileTypeId}/${ref}`);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async findOne(fileTypeId : string, ref : string) {
    try {
      const response = await api.get(`/files/unique/${fileTypeId}/${ref}`);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }
  
}