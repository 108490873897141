<script setup>
import { ref,onMounted } from "vue";

const erros = ref([]);
const showModalError = ref(false);

const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}

const props = defineProps({
  errosLista: { type : Object },
})

onMounted(async () => {
  erros.value = props.errosLista;
  showModalError.value = true;
  scrollToTop();
});

</script>

<template>    
  <Dialog class="modal-error" v-model:visible="showModalError" modal header="Erro" :style="{ width: '30rem' }">
    <div class="text-7xl text-center icone-erro text-red-500 my-2	 ">
      <font-awesome-icon icon="circle-exclamation" />
    </div>
    <ul class="text-red-700">
      <li class="mb-1" v-for="(item, index) in erros" :key="index">
        • {{ String(item).toUpperCase() }}
      </li>
    </ul>
  </Dialog>
</template>

<style scoped>


label{
  font-weight: bold;
}
.p-fluid .p-fileupload .p-button {
  width: 30px;
}


.dont-match{
  position: relative;
  margin-bottom: 4px;
  width: 100%;
  height: 10px;
  background-color: red;
}



</style>
