export function uniqueId(length = 4) {
    const time =  Date.now().toString(36);
    const characters ='abcdefghijklmnopqrstuvwxyz0123456789';
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    const id = time+''+result
    return id.replaceAll(' ','');
}