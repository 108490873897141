export default {
  "login": {
    "tituloPrincipal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FLEET MANAGEMENT"])},
    "subTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FLEET MONITORING AND SECURITY"])},
    "tituloSecundario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your details"])},
    "inputUsuario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username / LDAP / IAM"])},
    "inputSenha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "lembrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
    "esqueceu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "loginAzure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in with Azure"])},
    "semConta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account? Contact you administrator!"])},
    "userSenhaRequeridos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username and password required"])}
  }
}