import { api } from "./api";

export class FusionService {
  async getEntregas(plate: string, date: string) {
    try {
      const response = await api.get(`/fusion/entregas/${plate}/${date}`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }
}