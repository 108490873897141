import { api } from "./api";

export class TrackerManufacturerService {
  async post(value: string) {
    try {
      const response = await api.post(`/tracker-manufacturer`, value);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async get() {
    try {
      const response = await api.get(`/tracker-manufacturer`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }

  async getById(id: string) {
    try {
      const response = await api.get(`/tracker-manufacturer/${id}`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }

  async patch(value: string, id: string) {
    try {
      const response = await api.patch(`/tracker-manufacturer/${id}`, value);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async delete(id: string) {
    try {
      const response = await api.delete(`/tracker-manufacturer/${id}`);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }
}
