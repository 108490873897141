<template>
  <router-view />
  <Toast position="top-right" group="tr" />
  <ConfirmDialog></ConfirmDialog>
</template>

<script setup>
import { inject } from "vue";
import { defaultTheme } from "@/layout/composables/defalutTheme.ts";
import { useToast } from "primevue/usetoast";
const toast = useToast();

const emitter = inject("emitter");

emitter.on("toast", (value) => {
  if (value) {
    toast.add({
      severity: value.severity,
      summary: value.summary,
      detail: value.detail,
      group: "tr",
      life: 6000,
    });
  }
});

/* const script = document.createElement("script");
script.src =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyDQ-IezUFxYkJGkmmvlBmv_gP99tdus--s&loading=async";
document.body.appendChild(script); */

const { theme } = defaultTheme();
theme();
</script>
