export default {
  "login": {
    "tituloPrincipal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GESTIÓN DE FLOTAS"])},
    "subTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MONITOREO Y SEGURIDAD DE FLOTA"])},
    "tituloSecundario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingresa tus datos"])},
    "inputUsuario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario / LDAP / IAM"])},
    "inputSenha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "lembrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recordar"])},
    "esqueceu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvido la contraseña?"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
    "loginAzure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión con Azure"])},
    "semConta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿No tienes una cuenta? ¡contacta a tu administrador!"])},
    "userSenhaRequeridos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requiere usuario y contraseña"])}
  }
}