<template>
  <div  class="area-preload flex justify-content-center align-content-center flex-wrap">
      <ProgressSpinner style="width: 60px; height: 60px" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" aria-label="Custom ProgressSpinner" />
  </div>
</template>

<style scoped>
  .area-preload{
    width: 100%;
    height: 300px;
  }
</style>
