export default {
  "login": {
    "tituloPrincipal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GESTÃO DE FROTAS"])},
    "subTitulo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MONITORAMENTO E SEGURANÇA DE FROTAS"])},
    "tituloSecundario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor insira seus dados"])},
    "inputUsuario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario / LDAP / IAM"])},
    "inputSenha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
    "lembrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lembrar-me"])},
    "esqueceu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueceu a Senha?"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
    "loginAzure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar com Azure"])},
    "semConta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não tem uma conta? Contate seu administrador!"])},
    "userSenhaRequeridos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário e senha são requeridos"])}
  },
  "pt-br": {
    "startsWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Começa com"])},
    "contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contém"])},
    "notContains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não contém"])},
    "endsWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termina com"])},
    "equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igual"])},
    "notEquals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diferente"])},
    "noFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem Filtro"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])},
    "lt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menor que"])},
    "lte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menor ou igual a"])},
    "gt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maior que"])},
    "gte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maior ou igual a"])},
    "dateIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data é"])},
    "dateIsNot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data não é"])},
    "dateBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data antes de"])},
    "dateAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data depois de"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalizado"])},
    "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar"])},
    "matchAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponder Todos"])},
    "matchAny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponder Qualquer"])},
    "addRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar Regra"])},
    "removeRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover Regra"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sim"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolher"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concluído"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendente"])},
    "fileSizeTypes": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KB"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MB"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GB"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TB"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PB"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EB"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZB"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YB"])}
    ],
    "dayNames": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domingo"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segunda-feira"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terça-feira"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quarta-feira"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quinta-feira"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexta-feira"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sábado"])}
    ],
    "dayNamesShort": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dom"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seg"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ter"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qua"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sáb"])}
    ],
    "dayNamesMin": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S"])}
    ],
    "monthNames": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janeiro"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fevereiro"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Março"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abril"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maio"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junho"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julho"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agosto"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setembro"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outubro"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembro"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezembro"])}
    ],
    "monthNamesShort": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fev"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mar"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abr"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jun"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jul"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ago"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Out"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nov"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dez"])}
    ],
    "chooseYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolher Ano"])},
    "chooseMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolher Mês"])},
    "chooseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolher Data"])},
    "prevDecade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Década Anterior"])},
    "nextDecade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima Década"])},
    "prevYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ano Anterior"])},
    "nextYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo Ano"])},
    "prevMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mês Anterior"])},
    "nextMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo Mês"])},
    "prevHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora Anterior"])},
    "nextHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima Hora"])},
    "prevMinute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuto Anterior"])},
    "nextMinute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo Minuto"])},
    "prevSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundo Anterior"])},
    "nextSecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo Segundo"])},
    "am": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AM"])},
    "pm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PM"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoje"])},
    "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agora"])},
    "weekHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem"])},
    "firstDayOfWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["0"])},
    "showMonthAfterYear": false,
    "dateFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dd/MM/yy"])},
    "weak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraco"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médio"])},
    "strong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forte"])},
    "passwordPrompt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite uma senha"])},
    "emptyFilterMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum resultado encontrado"])},
    "searchMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Existem ", _interpolate(_list(0)), " resultados disponíveis"])},
    "selectionMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " itens selecionados"])},
    "emptySelectionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum item selecionado"])},
    "emptySearchMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum resultado encontrado"])},
    "emptyMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhuma opção disponível"])},
    "aria": {
      "trueLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verdadeiro"])},
      "falseLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falso"])},
      "nullLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não Selecionado"])},
      "star": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 estrela"])},
      "stars": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("star")), " estrelas"])},
      "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os itens selecionados"])},
      "unselectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os itens desmarcados"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fechar"])},
      "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
      "navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navegação"])},
      "scrollTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolar para o Topo"])},
      "moveTop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover para o Topo"])},
      "moveUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover para Cima"])},
      "moveDown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover para Baixo"])},
      "moveBottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover para o Final"])},
      "moveToTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover para o Destino"])},
      "moveToSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover para a Origem"])},
      "moveAllToTarget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover Todos para o Destino"])},
      "moveAllToSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover Todos para a Origem"])},
      "pageLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Página ", _interpolate(_named("page"))])},
      "firstPageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeira Página"])},
      "lastPageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última Página"])},
      "nextPageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próxima Página"])},
      "previousPageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página Anterior"])},
      "rowsPerPageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linhas por página"])},
      "jumpToPageDropdownLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir para a Página"])},
      "jumpToPageInputLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir para a Página"])},
      "selectRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linha Selecionada"])},
      "unselectRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linha Desmarcada"])},
      "expandRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expandir Linha"])},
      "collapseRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recolher Linha"])},
      "showFilterMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar Menu de Filtro"])},
      "hideFilterMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esconder Menu de Filtro"])},
      "filterOperator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operador de Filtro"])},
      "filterConstraint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restrição de Filtro"])},
      "editRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar Linha"])},
      "saveEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvar Edição"])},
      "cancelEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar Edição"])},
      "listView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualização de Lista"])},
      "gridView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualização de Grade"])},
      "slide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deslizar"])},
      "slideNumber": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slide ", _interpolate(_named("slideNumber"))])},
      "zoomImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ampliar Imagem"])},
      "zoomIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ampliar"])},
      "zoomOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduzir"])},
      "rotateRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotacionar para a Direita"])},
      "rotateLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rotacionar para a Esquerda"])}
    }
  }
}