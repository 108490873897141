<script setup>
  import { ref } from 'vue';

  const props = defineProps({
    titulo: { type : String },
    icone: { type : String },
  })
  
  const isIcon = ref(props.icone);
  
</script>

<template>
  <div class="card p-0 ">
    <div class="px-4 py-3 border-bottom-1 surface-border		">
      <h5 class="m-0 ">
        <font-awesome-icon v-if="isIcon" class="text-primary" :icon="icone" />
        {{ titulo }}
      </h5>
    </div>
    <div class="p-fluid formgrid grid p-4">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
</style>
