<script setup >
import { ref, onMounted, computed, inject } from "vue";
import { uniqueId } from "@/helpers/uniqueId";
import { Fancybox } from "@fancyapps/ui";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { ArquivoService } from "@/service/arquivo.service";

const API_URL = inject('API_URL')
const apiFolderStorage = `${API_URL}/storage`;

const confirm = useConfirm();
const toast = useToast();

const props = defineProps({
  acceptUpload: { type : String },
  titulo : { type : String },
  qte: { type : Number },
  token: { type : String },
  fileTypeId: { type : String },
  tableName: { type : String },
})

const dataUpload = {
  ref: props.token,
  fileTypeId: props.fileTypeId,
  tableName: props.tableName,
};

let service  = null

const progressInfos = ref([]) 
const arquivoNome = ref("")
const uploaded = ref(0)
const arquivosGaleria = ref([])
const arquivo = ref({
  id: null,
  file_name: null,
  original_name: null,
  file_type_id: null,
  file_type: null
})

const message = ref('')
const errorMessages = ref([])
const qteImages = ref(0)
const selectedFiles = ref(undefined)
const fileInput = ref(null);
const isVisibleUploadUnico = ref(false);
const showBtnDelete = ref(false);

const optionsFancyBox = {
  Carousel: {
    infinite: true,
  },
  Thumbs: {
    type: "classic",
  },
}

const tema = computed(() => {  
  return (props.qte == 1) ? 'simples' : 'multiplos'
})

onMounted(async () => {
  config()  
  initFancybox()
  loadFiles()
});

const config = () => {
  service = new ArquivoService()
}
  
const initFancybox = () => {
  Fancybox.bind('[data-fancybox]', optionsFancyBox);   
}

const addMsgErro = (errorMsg) => {  
  let id = uniqueId()
  let erro = { 
    id: id, 
    texto: errorMsg
  };
  errorMessages.value.push(erro);
}

const loadFiles = () =>{
  let ref = props.token ;
  let fileTypeId = props.fileTypeId;

  if(tema.value == 'multiplos')
  {
    service.findAll(fileTypeId, ref )
    .then((response) => {
      arquivosGaleria.value = (response) ? response.data.data : [];
    });
  }

  if(tema.value == 'simples')
  {
    service.findOne(fileTypeId, ref )
    .then((response) => {
      if(response?.status !== 422){
        arquivo.value = (response) ? response?.data.data : null;
        console.log(response)
        isVisibleUploadUnico.value = false;
        showBtnDelete.value = true;
      }else{
        isVisibleUploadUnico.value = true;
      }
    });
  }

}

const clearFile = () => {
  fileInput.value = null;
  progressInfos.value = [];
  selectedFiles.value = undefined;
  uploaded.value = 0;
}

const upload = (idx, file) =>{
  arquivoNome.value = ""; 
  progressInfos.value[idx] = { 
    percentage: 0, 
    fileName: file.name 
  };
  service.upload(file, dataUpload, this, (event) => {
    progressInfos.value[idx].percentage = Math.round(100 * event.loaded / event.total);				
  })
  .then((response) => {
    if(response.data.status === false){
      addMsgErro(response.data.errorMessage);
      fileInput.value = null;
    }else{
      let fullUploaded = ++uploaded.value
      if(fullUploaded == qteImages.value){
        loadFiles();
        clearFile();
      }
    }
  })
  .catch((error) => {
    let errorMsg = error.response.data.fileName + ' - ' + error.response.data.errors.arquivo;
    addMsgErro(errorMsg);
    fileInput.value = null;
  })
  .finally(()=>{
    loadFiles();
    clearFile();
  })
}

const selectFile = (event) =>{
  progressInfos.value = [];
  selectedFiles.value = event.target.files;
  errorMessages.value = [];
  uploadFiles();
}

const uploadFiles = () => {
  message.value = "";
  qteImages.value = selectedFiles.value.length;
  for (let i = 0; i < qteImages.value; i++) {
    upload(i, selectedFiles.value[i]);
  }
}

const getFullUrlImg = (arquivo,prefixo) => {
  return `${apiFolderStorage}/${prefixo}-${arquivo}`;
}

const onConfirmDelete = (arquivo) => {
  confirm.require({
    message: 'Deseja excluir este arquivo?',
    header: 'Excluir arquivo',
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-secondary p-button-outlined',
    rejectLabel: 'Cancelar',
    acceptLabel: 'Excluir',
    accept: () => {      
      service.deleteArquivo(arquivo.id)
      .then((response) => {        
        toast.add({ 
          severity: 'success', 
          summary: 'Confirmed', 
          detail: response.data.message, 
          life: 3000 
        });
      });
      if(tema.value == 'multiplos'){
        let index = arquivosGaleria.value.findIndex( item => item.id === arquivo.id );
        arquivosGaleria.value.splice(index, 1);
      }else{
        isVisibleUploadUnico.value = true;
        showBtnDelete.value = false;
      }
    },
  });
};

</script>

<template>
  <CardBase :titulo="props.titulo" icone="fa-regular fa-image" class="min-h-full">
    <div class="col-12">
      <div class="">
        <label class="file-input__label p-button mb-3" v-if="tema === 'multiplos'" >
          <input 
            type="file" 
            ref="fileInput"
            @change="selectFile" 
            :accept="acceptUpload"
            :disabled="selectedFiles"
            multiple
            hidden
          />
          <i class="fa-solid fa-upload"></i>
          <span class="btn-up">Upload</span>
        </label>
        <label class="file-input__label p-button mx-auto" v-if="tema == 'simples' && progressInfos.length == 0 " v-show="isVisibleUploadUnico" >
          <input 
            type="file" 
            ref="fileInput"
            @change="selectFile" 
            :accept="acceptUpload"
            :disabled="selectedFiles"
            hidden
          />
          <i class="fa-solid fa-upload"></i>
          <span class="btn-up">Upload</span>
        </label>
      </div>
    </div>
    <div class="col-12 mb-3 mensagens_de_erro" v-if="errorMessages.length > 0">
      <Message  v-for="(item, i) in errorMessages" :key="i" severity="error">{{ item.texto }}</Message>
    </div>
    <div class="col-12 mb-3 progressInfos" v-if="progressInfos.length > 0">
      <div class=" mb-2"
        v-for="(progressInfo, index) in progressInfos"
        :key="index"
      >
        <div class="w-full text-center">
          <span>Carregando</span>
          <ProgressBar mode="indeterminate" style="height: 10px"></ProgressBar>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3" v-if="tema === 'multiplos'">
      <div class="grid flex " :options="optionsFancyBox">
        <div class="col-2 card-img mb-3"
          v-for="(item, index) in arquivosGaleria"
          :key="index"
        >
          <div>
            <a :href="getFullUrlImg(item.file_name,'max')" data-fancybox="gallery" >
              <img :src="getFullUrlImg(item.file_name,'square')" alt="" class="w-full">
            </a>
          </div>
          <div class="d-flex justify-content-center">
            <a style="" class="btn-deletar-arquivo text-danger" @click=onConfirmDelete(item)>
              <i class="fa-solid fa-circle-xmark"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" v-if="tema === 'simples' ">
      <div class="mt-3" >
        <div class="card-img" v-show="!isVisibleUploadUnico">
          <div>
            <!-- <a :href="getDynamicStyle()" data-fancybox="gallery" >
              <img :src="url_miniatura" alt="" class="w-full">
            </a> -->
            <a :href="getFullUrlImg(arquivo.file_name,'max')" data-fancybox="gallery" >
              <img :src="getFullUrlImg(arquivo.file_name,'square')" alt="" class="w-full">
            </a>
          </div>
          <div class="d-flex justify-content-center" v-show="showBtnDelete">
            <a style="" class="btn-deletar-arquivo text-danger" @click=onConfirmDelete(arquivo)>
              <i class="fa-solid fa-circle-xmark"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </CardBase>
</template>

<style scoped>

.btn-deletar-arquivo{
  font-size: 28px;
  color: #c51212;
  margin: 0 auto;
  display: block;
  cursor: pointer;
  width: fit-content;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  display: block;
  width: fit-content;
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

</style>
