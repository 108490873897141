import datatheme from "../../../public/demo/data/theme.json";
export function defaultTheme() {
  const theme = () => {
    const themeLink = document.getElementById("theme-link") as HTMLElement;
    const themeLinkHref = String(themeLink.getAttribute("href"));
    const colorScheme = datatheme.colorScheme;
    const theme = datatheme.theme;
    const newHref = themeLinkHref.replace(
      "light/blue",
      `${colorScheme}/${theme}`
    );
    themeLink.setAttribute("href", newHref);
  };

  return {
    theme,
  };
}
