import { api } from "./api";

export class GenderService {
  async post(value: string) {
    try {
      const response = await api.post(`/gender`, value);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async get() {
    try {
      const response = await api.get(`/gender`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }

  async getById(id: string) {
    try {
      const response = await api.get(`/gender/${id}`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }

  async patch(value: string, id: string) {
    try {
      const response = await api.patch(`/gender/${id}`, value);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async delete(id: string) {
    try {
      const response = await api.delete(`/gender/${id}`);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }
}
