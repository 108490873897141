import { AbilityService } from "./ability.service";
import { AuthenticationService } from "./auth.service";
import { CityService } from "./city.service";
import { ClientService } from "./client.service";
import { CountryService } from "./country.service";
import { DriverLicenseService } from "./driverLicense.service";
import { FuelService } from "./fuel.service";
import { FusionService } from "./fusion.service";
import { GenderService } from "./gender.service";
import { MobileNetworkOperatorService } from "./mobileNetworkOperator.service";
import { openRouteService } from "./openRoute.service";
import { PoliceReportNatureService } from "./policeReportNature.service";
import { PoliceReportRecordService } from "./policeReportRecord.service";
import { PoliceReportTypeService } from "./policeReportType.service";
import { PositionService } from "./position.service";
import { RoleService } from "./role.service";
import { RotaService } from "./rota.service";
import { StateService } from "./state.service";
import { StatusService } from "./status.service";
import { SubclientService } from "./subclient.service";
import { TimeZoneService } from "./timeZone.service";
import { TrackerService } from "./tracker.service";
import { TrackerManufacturerService } from "./trackerManufacturer.service";
import { TrackerModelService } from "./trackerModel.service";
import { TrackerTypeService } from "./trackerType.service";
import { TransmissionTypeService } from "./transmissionType.service";
import { UserService } from "./user.service";
import { VehicleService } from "./vehicle.service";
import { VehicleManufacturerService } from "./vehicleManufacturer.service";
import { VehicleModelService } from "./vehicleModel.service";

//Api
const ability = new AbilityService();
const auth = new AuthenticationService();
const city = new CityService();
const client = new ClientService();
const country = new CountryService();
const driverLicense = new DriverLicenseService();
const fuel = new FuelService();
const gender = new GenderService();
const mobileNetworkOperator = new MobileNetworkOperatorService();
const policeReportType = new PoliceReportTypeService();
const policeReportNature = new PoliceReportNatureService();
const policeReportRecord = new PoliceReportRecordService();
const role = new RoleService();
const rota = new RotaService();
const state = new StateService();
const status = new StatusService();
const subclient = new SubclientService();
const timeZone = new TimeZoneService();
const tracker = new TrackerService();
const trackerManufacturer = new TrackerManufacturerService();
const trackerModel = new TrackerModelService();
const trackerType = new TrackerTypeService();
const transmissionType = new TransmissionTypeService();
const user = new UserService();
const vehicle = new VehicleService();
const vehicleManufacturer = new VehicleManufacturerService();
const vehicleModel = new VehicleModelService();
const position = new PositionService();
const fusion = new FusionService();
const openRoute = new openRouteService();

export const repositoryService = {
  ability,
  auth,
  city,
  client,
  country,
  driverLicense,
  fuel,
  fusion,
  gender,
  mobileNetworkOperator,
  openRoute,
  policeReportType,
  policeReportNature,
  policeReportRecord,
  position,
  role,
  rota,
  state,
  status,
  subclient,
  timeZone,
  tracker,
  trackerManufacturer,
  trackerModel,
  trackerType,
  transmissionType,
  user,
  vehicle,
  vehicleManufacturer,
  vehicleModel,
};
