import { api } from "./api";

export class PositionService {
  async getPosicao() {
    try {
      const response = await api.get(`/position`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }
  async getTrecho(imei: string) {
    try {
      const response = await api.get(`/position/trecho/${imei}`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }
}