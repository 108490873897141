import { api } from "./api";

export class RotaService {
  async getPosicao() {
    try {
      const response = await api.get(`/rotas/posicao`);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async getEntrgasFusio() {
    try {
      const response = await api.get(`rotas/entregas-fusion`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }

  async getPosicaoFusion() {
    try {
      const response = await api.get(`rotas/posicao-fusion`);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }
}