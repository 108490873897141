import {
  createRouter,
  createWebHashHistory,
  NavigationGuardNext,
  RouteRecordRaw,
} from "vue-router";
import AppLayout from "@/layout/AppLayout.vue";
import { session } from "@/helpers/localStorageHelper";
import { repositoryService } from "../service/repository.sevice";

const isAuthenticated = async () => {
  const isAuth = await repositoryService.auth
    .isAuthenticated()
    .then((response) => {
      if(response){
        if (response.status === 401) {
          return false;
        } else {
          return true;
        }
      }else{
        return false;
      }
    });
  
  return isAuth;
};

//const userRoles = ['super-admin','admin','operador'];
/* 
meta: {
  roles: ["super-admin"],
},
*/

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: AppLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/",
        name: "home",
        meta: {
          breadcrumb: ["monitoramento"],
        },
        component: () => import("@/views/monitoramento/OpenMaps.vue"),
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/apps/Settings.vue"),
      },
      {
        path: "/monitoramento",
        name: "monitoramento",
        children: [
          {
            path: "open-maps",
            name: "openMaps",
            component: () => import("@/views/monitoramento/OpenMaps.vue"),
          },
        ],
      },
      {
        path: "/permissao",
        children: [
          {
            path: "",
            name: "permissao",
            component: () => import("@/views/permissao/main.vue"),
          },
          {
            path: "editar/:id",
            name: "permissaoEditar",
            component: () => import("@/views/permissao/main.vue"),
          },
        ],
      },
      {
        path: "/cidade",
        children: [
          {
            path: "",
            name: "cidade",
            component: () => import("@/views/cidade/main.vue"),
          },
          {
            path: "editar/:id",
            name: "cidadeEditar",
            component: () => import("@/views/cidade/main.vue"),
          },
        ],
      },
      {
        path: "/pais",
        children: [
          {
            path: "",
            name: "pais",
            component: () => import("@/views/pais/main.vue"),
          },
          {
            path: "editar/:id",
            name: "paisEditar",
            component: () => import("@/views/pais/main.vue"),
          },
        ],
      },
      {
        path: "/habilitacao",
        children: [
          {
            path: "",
            name: "habilitacao",
            component: () => import("@/views/habilitacao/main.vue"),
          },
          {
            path: "editar/:id",
            name: "habilitacaoEditar",
            component: () => import("@/views/habilitacao/main.vue"),
          },
        ],
      },
      {
        path: "/combustivel",
        children: [
          {
            path: "",
            name: "combustivel",
            component: () => import("@/views/combustivel/main.vue"),
          },
          {
            path: "editar/:id",
            name: "combustivelEditar",
            component: () => import("@/views/combustivel/main.vue"),
          },
        ],
      },
      {
        path: "/genero",
        children: [
          {
            path: "",
            name: "genero",
            component: () => import("@/views/genero/main.vue"),
          },
          {
            path: "editar/:id",
            name: "generoEditar",
            component: () => import("@/views/genero/main.vue"),
          },
        ],
      },
      {
        path: "/operadora",
        children: [
          {
            path: "",
            name: "operadora",
            component: () => import("@/views/operadora/main.vue"),
          },
          {
            path: "editar/:id",
            name: "operadoraEditar",
            component: () => import("@/views/operadora/main.vue"),
          },
        ],
      },
      {
        path: "/perfil",
        children: [
          {
            path: "",
            name: "perfil",
            component: () => import("@/views/perfil/main.vue"),
          },
          {
            path: "editar/:id",
            name: "perfilEditar",
            component: () => import("@/views/perfil/main.vue"),
          },
        ],
      },
      {
        path: "/estado",
        children: [
          {
            path: "",
            name: "estado",
            component: () => import("@/views/estado/main.vue"),
          },
          {
            path: "editar/:id",
            name: "estadoEditar",
            component: () => import("@/views/estado/main.vue"),
          },
        ],
      },
      {
        path: "/status",
        children: [
          {
            path: "",
            name: "status",
            component: () => import("@/views/status/main.vue"),
          },
          {
            path: "editar/:id",
            name: "statusEditar",
            component: () => import("@/views/status/main.vue"),
          },
        ],
      },
      {
        path: "/time-zone",
        children: [
          {
            path: "",
            name: "time_zone",
            component: () => import("@/views/timeZone/main.vue"),
          },
          {
            path: "editar/:id",
            name: "timeZoneEditar",
            component: () => import("@/views/timeZone/main.vue"),
          },
        ],
      },
      {
        path: "/tipo-transmissor",
        children: [
          {
            path: "",
            name: "tipoTransmissor",
            component: () => import("@/views/transmissorTipo/main.vue"),
          },
          {
            path: "editar/:id",
            name: "tipoTransmissorEditar",
            component: () => import("@/views/transmissorTipo/main.vue"),
          },
        ],
      },
      {
        path: "/tipo-rastreador",
        children: [
          {
            path: "",
            name: "tipoRastreador",
            component: () => import("@/views/rastreadorTipo/main.vue"),
          },
          {
            path: "editar/:id",
            name: "tipoRastreadorEditar",
            component: () => import("@/views/rastreadorTipo/main.vue"),
          },
        ],
      },
      {
        path: "/fabricante-rastreador",
        children: [
          {
            path: "",
            name: "fabricanteRastreador",
            component: () => import("@/views/rastreadorFabricante/main.vue"),
          },
          {
            path: "editar/:id",
            name: "fabricanteRastreadorEditar",
            component: () => import("@/views/rastreadorFabricante/main.vue"),
          },
        ],
      },
      {
        path: "/modelo-rastreador",
        children: [
          {
            path: "",
            name: "modeloRastreador",
            component: () => import("@/views/rastreadorModelo/main.vue"),
          },
          {
            path: "editar/:id",
            name: "modeloRastreadorEditar",
            component: () => import("@/views/rastreadorModelo/main.vue"),
          },
        ],
      },
      {
        path: "/fabricante-veiculo",
        children: [
          {
            path: "",
            name: "fabricanteVeiculo",
            component: () => import("@/views/veiculoFabricante/main.vue"),
          },
          {
            path: "editar/:id",
            name: "fabricanteVeiculoEditar",
            component: () => import("@/views/veiculoFabricante/main.vue"),
          },
        ],
      },
      {
        path: "/modelo-veiculo",
        children: [
          {
            path: "",
            name: "modeloVeiculo",
            component: () => import("@/views/veiculoModelo/main.vue"),
          },
          {
            path: "editar/:id",
            name: "modeloVeiculoEditar",
            component: () => import("@/views/veiculoModelo/main.vue"),
          },
        ],
      },
      {
        path: "/veiculo",
        children: [
          {
            path: "",
            name: "veiculo",
            component: () => import("@/views/veiculo/main.vue"),
          },
          {
            path: "cadastro",
            name: "veiculoCadastro",
            component: () => import("@/views/veiculo/Cadastro.vue"),
          },
          {
            path: "editar/:id",
            name: "veiculoEditar",
            component: () => import("@/views/veiculo/Cadastro.vue"),
          },
        ],
      },
      {
        path: "/rastreador",
        children: [
          {
            path: "",
            name: "rastreador",
            component: () => import("@/views/rastreador/main.vue"),
          },
          {
            path: "cadastro",
            name: "rastreadorCadastro",
            component: () => import("@/views/rastreador/Cadastro.vue"),
          },
          {
            path: "editar/:id",
            name: "rastreadorEditar",
            component: () => import("@/views/rastreador/Cadastro.vue"),
          },
        ],
      },
      {
        path: "/cliente",
        children: [
          {
            path: "",
            name: "cliente",
            component: () => import("@/views/cliente/main.vue"),
          },
          {
            path: "cadastro",
            name: "clienteCadastro",
            component: () => import("@/views/cliente/Cadastro.vue"),
          },
          {
            path: "editar/:id",
            name: "clienteEditar",
            component: () => import("@/views/cliente/Cadastro.vue"),
          },
        ],
      },
      {
        path: "/subcliente",
        children: [
          {
            path: "",
            name: "subcliente",
            component: () => import("@/views/subCliente/main.vue"),
          },
          {
            path: "cadastro",
            name: "subclienteCadastro",
            component: () => import("@/views/subCliente/Cadastro.vue"),
          },
          {
            path: "editar/:id",
            name: "subclienteEditar",
            component: () => import("@/views/subCliente/Cadastro.vue"),
          },
        ],
      },
      {
        path: "/usuario",
        name: "usuario",
        children: [
          {
            path: "",
            name: "usuarioListar",
            component: () => import("@/views/usuario/main.vue"),
          },
          {
            path: "cadastro",
            name: "usuarioCadastro",
            component: () => import("@/views/usuario/Cadastro.vue"),
          },
          {
            path: "editar/:id",
            name: "usuarioEditar",
            component: () => import("@/views/usuario/Cadastro.vue"),
          },
        ],
      },
      {
        path: "/operacoes",
        children: [
          {
            path: "pontoatencao",
            name: "pontoatencao",
            component: () => import("@/views/dashboards/PontosAtencao.vue"),
          },
        ],
      },
      {
        path: "/prevencao",
        children: [
          {
            path: "",
            name: "prevencaoQualificada",
            component: () =>
              import("@/views/prevencao/PrevencaoQualificada.vue"),
          },
          {
            path: "tipo",
            name: "tipoPrevencao",
            component: () => import("@/views/prevencao/PrevencaoQualificada.vue"),
          },
          {
            path: "natureza",
            name: "naturezaPrevencao",
            component: () => import("@/views/prevencao/PrevencaoQualificada.vue"),
          }
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/Login.vue"),
    beforeEnter: async (to, from, next) => {
      const isAuth = await isAuthenticated();
      if (isAuth !== false) {
        return next("/");
      }
      return next();
    },
  },
  {
    path: "/access",
    name: "accessDenied",
    component: () => import("@/views/pages/AccessDenied.vue"),
  },
  {
    path: "/auth/forgotpassword",
    name: "forgotpassword",
    component: () => import("@/views/auth/ForgotPassword.vue"),
  },
  {
    path: "/auth/newpassword",
    name: "newpassword",
    component: () => import("@/views/auth/NewPassword.vue"),
  },
  {
    path: "/auth/verification",
    name: "verification",
    component: () => import("@/views/auth/Verification.vue"),
  },
  {
    path: "/auth/lockscreen",
    name: "lockscreen",
    component: () => import("@/views/auth/LockScreen.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notfound",
    component: () => import("@/views/pages/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const fetchLogin = async (next: NavigationGuardNext) => {
  const isAuth = await isAuthenticated()
  if (isAuth === false) {
    session.clear();
    next("/login");
  } else {
    next();
  }
};

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    fetchLogin(next);
  } else {
    next();
  }
});

export default router;
