import { api } from "./api";

export class MobileNetworkOperatorService {
  async post(value: string) {
    try {
      const response = await api.post(`/mobile-network-operator`, value);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async get() {
    try {
      const response = await api.get(`/mobile-network-operator`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }

  async getById(id: string) {
    try {
      const response = await api.get(`/mobile-network-operator/${id}`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }

  async patch(value: string, id: string) {
    try {
      const response = await api.patch(`/mobile-network-operator/${id}`, value);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async delete(id: string) {
    try {
      const response = await api.delete(`/mobile-network-operator/${id}`);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }
}
