import { api } from "./api";

export class VehicleModelService {
  async post(value: string) {
    try {
      const response = await api.post(`/vehicle-model`, value);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async get() {
    try {
      const response = await api.get(`/vehicle-model`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }

  async getByMontadoraId(id: string){
    try {
      const response = await api.get(`/vehicle-model/manufacturer/${id}`);
      return response.data.data;
    } catch (error: any) {
      return error.response
    }
  }

  async getById(id: string) {
    try {
      const response = await api.get(`/vehicle-model/${id}`);
      return response.data.data;
    } catch (error: any) {
      return error.response;
    }
  }

  async patch(value: string, id: string) {
    try {
      const response = await api.patch(`/vehicle-model/${id}`, value);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }

  async delete(id: string) {
    try {
      const response = await api.delete(`/vehicle-model/${id}`);
      return response;
    } catch (error: any) {
      return error.response;
    }
  }
}
